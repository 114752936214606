.globalWrapper {
    height: 100vh;
    width: 100%;
}

.globalContentWrapper {
    /* padding: 1rem; */
    /* Add some padding around content */
    padding-top: calc(6vh + 1rem);
    margin-left: 8vw;
    /* Make space for the sidebar */
    overflow-y: auto;
    /* Enable scrolling for long content */
}