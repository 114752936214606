.mainWrapper {
    position: fixed;
    display: flex;
    /*Allowhorizontallayoutforcontent*/
    justify-content: space-between;
    /*Aligncontenthorizontally*/
    align-items: center;
    /*Aligncontentvertically*/
    height: calc(6vh - 1px);
    /*Setheaderheight*/
    width: calc(100% - 2rem);
    /*Setheaderwidthtofullviewport*/
    background-color: #f0f0f0;
    /*Examplebackgroundcolor*/
    padding: 0.5rem 1rem;
    /*Addsomepadding*/

    background-color: #0B0A0B;
    border-bottom: 1px solid #272527;
    color: #fdfdfd;
}