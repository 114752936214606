.mainWrapper{
    height: 100%;
    width: 100%;
    background-color: #0B0A0B;
    color: white;
}
.headerWrapper{
    width: calc(100% - 40px);
    padding: 20px 20px 10px;
}
.headerContainer{
    width: calc(100% - 20px);
    background-color: #343a40;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerTitle{
    font-weight: 600;
}
.createNew{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.createNew a{
    background-color: aliceblue;
    padding: 8px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
    font-size: 0.7rem;
    color: #0B0A0B;
}
.tableWrapper{
    padding: 0px 20px;
    width: calc(100% - 40px);
}
.tableContainer{
    width: 100%;
    height: 80vh;
    background-color: #343a40;
    
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.tableHeader{
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tableContent{
    height: 70vh;
}
.eachRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}