.mainWrapper {
    position: fixed;
    /*Fixsidebartotheleftside*/
    top: calc(6vh + 1rem);
    /*Startatthetopoftheviewport*/
    left: 0;
    /*Positionattheleftedge*/
    height: calc(94vh - 1rem);
    /*Setsidebarheighttofullviewport*/
    width: 8vw;
    /*Setsidebarwidthto8%ofviewportwidth*/
    background-color: #e0e0e0;
    /*Examplebackgroundcolor*/
    padding: 1rem 0px;
    /*Addsomepadding*/
    overflow-y: auto;
    /*Enablescrollingforlongsidebars*/
    background-color: #0B0A0B;

    border-right: 1px solid #1D1B1D;


    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.searchIconWrapper{
    height: 48px;
    width: 48px;
    background-color: #181818;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}


.mainContainer{
    background-color: #181818;
    padding: 20px 6px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.mainFooter{
    background-color: #181818;
}


.eachNav{
    height: 80px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #E9E7E8;
}
.eachNav:hover{
    color: white;
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
}
.navTxt{
    font-size: 0.7rem;
    font-weight: 600;
}