.mainWrapper{
    min-height: 140vh;
    width: 100%;
    background-color: #0B0A0B;
    color: white;
}
.headerWrapper{
    width: calc(100% - 40px);
    padding: 20px 20px 10px;
}
.headerContainer{
    width: calc(100% - 20px);
    background-color: #343a40;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerTitle{
    font-weight: 600;
}
.createNew{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.createNew a{
    background-color: aliceblue;
    padding: 8px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
    font-size: 0.7rem;
    color: #0B0A0B;
}


.formWrapper{
    width: calc(100% - 40px);
    padding: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formContainer{
    width: 100%;
    padding: 40px calc(20% - 20px);
    background-color: #343a40;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.eachFieldWrapper{
    padding: 10px 0px;
}
.eachTitleWrapper{
    font-size: 0.8rem;
    font-weight: 600;
}
.eachInputWrapper{
    width: 100%;
}
.eachInputWrapper input{
    width: calc(100% - 22px);
    margin: 4px 0px 0px;
    padding: 11px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
    text-transform: capitalize;
}
.eachInputWrapper textarea{
    width: calc(100% - 22px);
    margin: 4px 0px 0px;
    padding: 11px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 100px;
}

.actions{
    width: 100%;
    padding: 10px 0px 20px;
}
.actions button{
    padding: 10px 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    border: 1px solid #343a40;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
}