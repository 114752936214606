.mainWrapper{
    background-color: #0B0A0B;
    height: 100vh;
    width: 100%;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
}
.eachCardWrapper{
    background-color: #343a40;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 40px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    color: white;
    border: 1px solid #100F10;
    min-width: 110px;
}
.eachCardWrapper:hover{
    border: 1px solid #FFFFFF80;
}
.navTxt{
    font-weight: 600;
    padding: 20px 0px 0px;
    color: #FFFFFF80;
}